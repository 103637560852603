import { registerApplication, start, navigateToUrl } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./layout.html";

import { initializeAuth, logout, authManager } from "./auth-helper";

// Construct routes and applications
const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

// Register and start Single SPA app
const registerSpaApp = async (user, auth) => {
  const authUser = {
    name: `${user?.family_name || ""} ${user?.given_name || ""}`.trim() || user?.nickname || user?.name || user?.username,
    picture: user?.picture || (user?.localAccountId ? `https://arp.mantu.com/aBook/Telecom/GetEmployeeThumbnail/${user?.localAccountId}` : ""),
    email: user?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"] || user?.email || user?.username || ""
  };
 
  applications.forEach((props) => {
    // Add custom properties accessible to sub applications
    props.customProps = {
      examplePropRootConfig: "Example data from root Single SPA",
      authUser,
      auth,
      authManager
    };
    registerApplication(props);
  });
};

initializeAuth(registerSpaApp);
layoutEngine.activate();

start({
  urlRerouteOnly: true,
});

// Global event listeners from Micro front ends
window.addEventListener("signout", () => {
  console.log("Clearing localStorage...");
  localStorage.clear();
  console.log("Logging out...");
  logout();
});

window.addEventListener(
  "single-spa:before-routing-event",
  ({ detail: { oldUrl, newUrl, cancelNavigation } }) => {
    // TODO
  }
);
