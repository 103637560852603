import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./layout.html";
import { createAppInsightsLogger } from "@mantu/erp-spa-ai-logger";
import { initializeAuth, logout, authManager, getEntraPhotoUrl } from "./auth-helper";

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

// Register Single-SPA applications
const registerSpaApp = async (user, auth) => {
  const authUser = {
    name: `${user?.family_name || ""} ${user?.given_name || ""}`.trim() || user?.nickname || user?.name || user?.username,
    picture: user?.picture || (user?.localAccountId ? `https://arp.mantu.com/aBook/Telecom/GetEmployeeThumbnail/${user?.localAccountId}` : ""),
    email: user?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"] || user?.email || user?.username || "",
    nameidentifier: user?.nameidentifier || user?.["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"] || "no-user",
  };

  const connectionString = process.env.APP_INSIGHTS_CONNECTION_STRING?.trim();

  const appInsights = connectionString
    ? createAppInsightsLogger({
        connectionString,
        userId: authUser.nameidentifier,
        enableAutoTracking: true,
        customTags: {
          "ai.cloud.role": "Single-SPA-Root",
          "ai.cloud.roleInstance": "RootInstance",
        },
      })
    : null;

  applications.forEach((props) => {
    props.customProps = {
      ...props.customProps,
      examplePropRootConfig: "Example data from root Single SPA",
      authUser,
      auth,
      authManager,
      getEntraPhotoUrl,
      ...(appInsights ? { appInsights } : {}),
    };
    registerApplication(props);
  });
};

initializeAuth(registerSpaApp);
layoutEngine.activate();
start({ urlRerouteOnly: true });

window.addEventListener("signout", () => {
  console.log("Clearing localStorage...");
  localStorage.clear();
  console.log("Logging out...");
  logout();
});

window.addEventListener("single-spa:before-routing-event", ({ detail: { oldUrl, newUrl } }) => {
  applications.forEach((props) => {
   //TBD
  });
});
